import FooterImage from '../assets/images/footer_image.webp';
import '../assets/styles/Footer.sass';

export default function Footer(){
  const openModal = () => {
    document.querySelector('.modal').style.display = 'flex';
  }

  return (
    <footer>
      <img src={FooterImage} alt="footer_logo" />
      <p>Direitos reservados a Mozak. Acesse a nossa <span onClick={openModal}><strong>Política de Privacidade.</strong></span></p>
      <span className='creci'>CRECI RJ-008658/O</span>
    </footer>
  );
}