import { sendData } from "./sendData";

export function checkData(name, email, phone, checkbox) {
    if (checkbox) {
      if (name != "" && email != "" && phone != "") {
        if(name.length < 4){
          alert("Nome mínimo de 4 caracteres");
          return;
        }

        if(phone.length < 15){
            alert("Preencha o telefone corretamente");
            return;
        }

        sendData(name, email, phone);
      } else {
        alert('Preencha todos os campos antes de enviar');
      }
    } else {
      alert('Preencha o checkbox antes de enviar');
    }
  }