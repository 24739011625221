import { getMediaFromURL } from "./getMediaFromURL";

export function sendData(name, email, phone) {
    var midia = getMediaFromURL();

    const formData = new FormData();
    formData.append('nome', name);
    formData.append('email', email);
    formData.append('tel_celular', phone);
    formData.append('id_produto', 35);
    formData.append('midia', midia);

    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        window.location.reload();
      })
  }