export function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }


    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }

    return 'Landing page';
}