
import '../assets/styles/Location.sass'
import Map from '../assets/images/location_map.webp'
import Location1 from '../assets/images/location1.webp'
import Location2 from '../assets/images/location2.webp'
import Location3 from '../assets/images/location3.webp'
import Location4 from '../assets/images/location4.webp'
import Left from '../assets/images/arrow_left.webp'
import Right from '../assets/images/arrow_right.webp'
import { useState } from 'react'

export default function Location(){
  const [index, setIndex] = useState(0)

  const locations = [
    Location1,
    Location2,
    Location3,
    Location4
  ]

  const leftClick = () => {
    if(index > 0){
      setIndex(index - 1)
    }else{
      setIndex(locations.length - 1)
    }
  }

  const rightClick = () => {
    if(index < locations.length - 1){

      setIndex(index + 1)
    }else{

      setIndex(0)
    }
  }

  return (
    <section className="location">

      <p className="title">LOCALIZAÇÃO</p>
      
      <div className="carrousel">
        <img className="left_arrow" src={Left} alt="" onClick={leftClick} />
        <img className="carrousel_image" src={locations[index]} alt="" />
        <img className="right_arrow" src={Right} alt="" onClick={rightClick} />
      </div>
      
      <div className="map">
        <img src={Map} alt="" />
      </div>
    </section>
  );
}