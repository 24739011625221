import '../assets/styles/Video.sass';
import VideoImage from '../assets/images/first_section.gif';
import React, { useState } from "react";
import { phoneMask } from '../utils/phoneMask';
import { checkData } from '../utils/checkData';

export default function Video() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const openModal = () => {
    document.querySelector('.modal').style.display = 'flex';
  }

  const activeLabel = (e) => {
    const label = e.target.parentNode.querySelector('label')
    label.classList.add('active')
  }

  const toggleLabel = (e) => {
    const input = e.target
    const label = e.target.parentNode.querySelector('label')

    if (input.value == "") {
      label.classList.remove('active')
      return
    }
  }

  return (
    <section className="video">

      <p className="title">VÍDEO</p>

      {/* <img src={VideoImage} alt="Video" className="video_gif" /> */}
      <div className="video_gif">
        <iframe src="https://www.youtube.com/embed/v4l6EeP04bQ?autoplay=1&mute=1" title="VILLA | Mozak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <div className="form">
        <div className="form_title">
          <h1>Cadastre-se para mais informações do projeto</h1>
        </div>

        <div className="form_inputs">
          <div className="form_input">
            <input type="text" id="name" name="name" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setName(e.target.value.trimStart())} />
            <label htmlFor="name">Nome</label>
          </div>

          <div className="form_input">
            <input type="email" id="email" name="email" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setEmail(e.target.value.trimStart())} />
            <label htmlFor="email">E-mail</label>
          </div>

          <div className="form_input">
            <input type="tel" id="phone" name="phone" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setPhone(e.target.value)} onInput={(e) => phoneMask(e)} />
            <label htmlFor="phone">Telefone</label>
          </div>
        </div>

        <div className="form_checkbox">
          <input type="checkbox" id="checkbox" name="checkbox" onChange={(e) => setCheckbox(e.target.checked)} />
          <label htmlFor="checkbox">Li e aceito os termos e <span onClick={openModal}>políticas de privacidade</span></label>
        </div>

        <div className="form_submit">
          <button type="submit" className="button" onClick={() => checkData(name, email, phone, checkbox)}>ENVIAR</button>
        </div>
      </div>

    </section>
  );
}