import Menu from '../assets/images/menu.webp';
import Mozak from '../assets/images/mozak.webp';
import Bsd from '../assets/images/bsd.webp';
import '../assets/styles/Navbar.sass';
import { IoMenu } from "react-icons/io5";

export default function Navbar(){

  const openMenu = () => {
    document.querySelector('.menu_nav').classList.toggle('active');
  }

  return (
    <div className="navbar">
      <div onClick={openMenu} className='menu'>
        <IoMenu />
        <img src={Mozak} alt="Mozak" className="mozak" />
      </div>
      {/* <img src={Menu} alt="Menu" className="menu_icon_navbar" onClick={openMenu}/> */}
      <img src={Bsd} alt="BSD" className="bsd" />
    </div>
  );
}