import '../assets/styles/Hero.sass';
import HeroImage from '../assets/images/hero_image.webp'
import HeroImageMobile from '../assets/images/hero_image_mobile.webp'
import HeroLogo from '../assets/images/hero_logo.webp'
import HeroLogoMobile from '../assets/images/hero_logo_mobile.webp'

export default function Hero() {

  const scrollToSection = (section) => {
    window.scrollTo({
      top: document.querySelector(section).offsetTop,
      behavior: 'smooth'
    });

  }

  return (
    <div className="hero">
      <div className='banner'>
        <h2>Invista com dupla rentabilidade no primeiro Double Suítes do Leblon</h2>
      </div>
      <div className="image">
        <h1>75% VENDIDO</h1>
        <div className='content'>
          <img className="logo_desktop" src={HeroLogo} alt="Hero" />
          {/* <h1><strong>75%</strong> VENDIDO</h1> */}
          <h3>Condição exclusiva: 30% do valor apenas na entrega das chaves</h3>
          <button onClick={() => scrollToSection('.video')}>SAIBA MAIS</button> 
        </div>

        <div className='overlay'></div>
        <img className="image_desktop" src={HeroImage} alt="Hero" />
        <img className="image_mobile" src={HeroImageMobile} alt="Hero" />
      </div>

    </div>
  );
}