import React, { useState } from "react";
import BookImage from '../assets/images/book_image.webp'
import '../assets/styles/Book.sass'
import { checkData } from "../utils/checkData";
import { phoneMask } from "../utils/phoneMask";

export default function Book(){
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const openModal = () => {
    document.querySelector('.modal').style.display = 'flex';
  }

  const activeLabel = (e) => {
    const label = e.target.parentNode.querySelector('label')
    label.classList.add('active')
  }

  const toggleLabel = (e) => {
    const input = e.target
    const label = e.target.parentNode.querySelector('label')

    if (input.value == "") {
      label.classList.remove('active')
      return
    }
  }

  return (
    <section className="book">
      <p className="title">BOOK</p>

      <div className="image">
        <img src={BookImage} alt="book_image" />
      </div>
      
      <div className="form">
        <h2>Cadastre-se para acessar o book e receber informações exclusivas dos nossos especialistas</h2>

        <div className="input_wrapper">
          <input type="text" id="book_name" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setName(e.target.value.trimStart())} />
          <label htmlFor="book_name">Nome</label>
        </div>
        
        <div className="input_wrapper">
          <input type="text" id="book_email" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setEmail(e.target.value.trimStart())} />
          <label htmlFor="book_email">Email</label>
        </div>
        
        <div className="input_wrapper">
          <input type="text" id="book_phone" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setPhone(e.target.value)} onInput={(e) => phoneMask(e)} />
          <label htmlFor="book_phone">Telefone</label>
        </div>

        <div className="checkbox_wrapper">
          <input type="checkbox" id="book_checkbox" required onChange={(e) => setCheckbox(e.target.checked)} />
          <label htmlFor="book_checkbox">Li e aceito os termos e <span onClick={openModal}>políticas de privacidade</span></label>
        </div>

        <button onClick={() => checkData(name, email, phone, checkbox)}>ENVIAR</button>
      </div>
    </section>
  );
}