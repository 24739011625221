import '../assets/styles/Project.sass'
import ProjectPeople from '../assets/images/project_people.webp'
import ProjectImage from '../assets/images/project_image.webp'
import ProjectText from '../assets/images/project_text.webp'

export default function Project(){
  return(
    <section className="project">
      <p className="title">O PROJETO</p>
      
      <div className="people">
        <img src={ProjectPeople} alt="pessoas" />
      </div>
      
      <div className="image">
        <img className="project_text" src={ProjectText} alt="" />
        <img className="project_image" src={ProjectImage} alt="" />
      </div>

    </section>
  );
}