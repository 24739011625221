import './assets/styles/App.sass';
import './assets/styles/Button.sass';
import React from "react";
import Modal from './components/Modal';
import DocumentMeta from "react-document-meta";
import Cta from './components/Cta';
import Navbar from './views/Navbar'
import Hero from './views/Hero'
import Video from './views/Video'
import Datasheet from './views/Datasheet'
import DatasheetText from './views/DatasheetText'
import Project from './views/Project'
import ProjectText from './views/ProjectText'
import Location from './views/Location'
import Book from './views/Book'
import Footer from './views/Footer'
import Menu from './components/Menu'
import AMozak from './views/AMozak';

function App() {
  const meta = {
    title: 'Villa - Mozak',
    description: 'Lançamento Leblon - Mozak',
    // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'mozak,leblon,empreendimento,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
      }
    }
  };

  return (
    <div className="App">
      <DocumentMeta {...meta} />

      <Navbar />

      <Hero />

      <Video />

      <Datasheet />

      <DatasheetText />

      <Project />

      <ProjectText />

      <Location />

      <Book />
      <AMozak />

      <Footer />

      <Modal />
      <Cta />
      <Menu />
    </div>
  );
}

export default App;
